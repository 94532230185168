import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Breadcrumbs items={[{
      name: 'Announcements',
      to: '/whats-new/announcements'
    }, {
      name: 'Canvas v10 Announcements'
    }]} mdxType="Breadcrumbs" />
    <h2 {...{
      "id": "canvas-ai-elements-release"
    }}>{`Canvas AI Elements Release`}</h2>
    <p>{`New AI elements are now available through Canvas! These elements are meant to be used for AI features and products `}</p>
    <h3 {...{
      "id": "sparkle-icon"
    }}>{`Sparkle Icon`}</h3>
    <p>{`A new `}<inlineCode parentName="p">{`wd-icon-sparkle.svg`}</inlineCode>{` icon (`}<inlineCode parentName="p">{`sparkleIcon`}</inlineCode>{` when using `}<a parentName="p" {...{
        "href": "/assets/system-icons#web-examples-1"
      }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) has been released. Upgrade to `}<inlineCode parentName="p">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="p">{`3.0.19`}</inlineCode>{`) to access the new sparkle icon.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This sparkle icon is also available for designers through the Canvas Assets Figma library.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "dragon-fruit-color"
    }}>{`Dragon Fruit Color`}</h3>
    <p>{`A new AI purple color, Dragon Fruit, has been added to the Canvas Tokens repository. Dragon Fruit is available as a base color token with six shade levels. To keep this color separate from the existing Canvas color palette, Dragon Fruit will use a slightly different naming convention (ex: `}<inlineCode parentName="p">{`extended-palette-dragon-fruit-100`}</inlineCode>{`) compared to the existing Canvas base colors. Upgrade to `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@workday/canvas-tokens-web"
      }}><inlineCode parentName="a">{`@workday/canvas-tokens-web@1.1.0`}</inlineCode></a>{` to access the new Dragon Fruit color.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`The new Dragon Fruit color is also available through the Canvas Tokens v2 Figma library for designers to utilize.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "loading-sparkle"
    }}>{`Loading Sparkle`}</h3>
    <p>{`A new Loading Sparkle component is now available in the Preview package of the Canvas Kit 10.3.0 release. This component is meant to indicate that content is currently being loaded or processed, similar to Loading Dots. `}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This component is also available in the Canvas Web v10 Figma library. `}</p>
    </InternalContent>
    <h2 {...{
      "id": "canvas-kit-1010-release"
    }}>{`Canvas Kit 10.1.0 Release`}</h2>
    <p>{`Canvas Kit 10.1.0 is now available.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This Canvas Kit release contains the following updates:`}</p>
      <ul>
        <li parentName="ul">{`Icon support for Delete buttons, which is already available on the Canvas Web v10 Figma library`}</li>
        <li parentName="ul">{`A new
`}<a parentName="li" {...{
            "href": "https://workday.github.io/canvas-kit/?path=%2Fdocs%2Ffeatures-styling-stencils--create-stencil"
          }}>{`Stencil utility`}</a>{`
to apply component styles`}</li>
      </ul>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`This Canvas Kit release contains the following updates:`}</p>
      <ul>
        <li parentName="ul">{`Icon support for Delete buttons`}</li>
        <li parentName="ul">{`A new
`}<a parentName="li" {...{
            "href": "https://workday.github.io/canvas-kit/?path=%2Fdocs%2Ffeatures-styling-stencils--create-stencil"
          }}>{`Stencil utility`}</a>{`
to apply component styles`}</li>
      </ul>
    </ExternalContent>
    <p>{`This update will not impact developers. To access these changes, please upgrade to Canvas Kit
10.1.0.`}</p>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "canvas-mobile-q3-release"
      }}>{`Canvas Mobile Q3 Release`}</h2>
      <p>{`Canvas Mobile’s Q3 release will be available on `}<strong parentName="p">{`November 8th, 2023`}</strong>{`. This release includes 6 new
components with new and updated usage guidance.`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Designer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Mobile Components`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New components being added to the Canvas Mobile Figma library include: Cards, Search Input, Prompt Input, Banner, Time Picker, and Hyperlink.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New and Updated Documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New and updated documentation will be released for all of the new components being released as part of the Canvas Mobile Q3 release in addition to the following components and patterns: Prompt, Read Only, Status Indicator.`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "new-mobile-components"
      }}>{`New Mobile Components`}</h3>
      <p>{`The following new components will be available in the Canvas Mobile Figma library:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Card:`}</strong>{` A flexible content container related to a single topic.`}</li>
        <li parentName="ul"><strong parentName="li">{`Search Input:`}</strong>{` An input for queries that pulls matching and related results.`}</li>
        <li parentName="ul"><strong parentName="li">{`Prompt Input:`}</strong>{` An input that triggers the searching/finding of instances within a prompt.`}</li>
        <li parentName="ul"><strong parentName="li">{`Banner:`}</strong>{` An indicator showing an important brief and concise message in context that requires
user interaction.`}</li>
        <li parentName="ul"><strong parentName="li">{`Time Picker:`}</strong>{` An input for entering time.`}</li>
        <li parentName="ul"><strong parentName="li">{`Hyperlink (iOS only):`}</strong>{` A button for external redirection outside of the app.`}</li>
      </ul>
      <p>{`All of these components will be available for iOS and Android, with the exception of Hyperlink,
which will only be available in iOS as part of this release. While Hyperlink is a component, it is
represented as a text style in Figma. To use Hyperlink, select one of the following text styles and
apply Blueberry 400 to it:`}</p>
      <ul>
        <li parentName="ul">{`Subtext L Link - Regular`}</li>
        <li parentName="ul">{`Body S Link - Regular`}</li>
        <li parentName="ul">{`Body M Link - Regular`}</li>
        <li parentName="ul">{`Body L Link - Regular`}</li>
      </ul>
      <p>{`This update will not impact designers or developers as they are all net-new components.`}</p>
      <h3 {...{
        "id": "new-and-updated-documentation"
      }}>{`New and Updated Documentation`}</h3>
      <p>{`Documentation will be available for all the new components released as part of this update.
Additionally, the following updates will also be made to mobile documentation on the Canvas Design
System site:`}</p>
      <ul>
        <li parentName="ul">{`New Prompt pattern documentation`}</li>
        <li parentName="ul">{`New Read Only UI pattern documentation`}</li>
        <li parentName="ul">{`Updated Status Indicator component documentation to include the recent design changes made in
previous releases (switch to sentence casing, increasing height)`}</li>
      </ul>
      <p>{`This update will not impact designers or developers as it is specific to Canvas documentation.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h2 {...{
        "id": "canvas-kit-v10-release"
      }}>{`Canvas Kit v10 Release`}</h2>
      <p>{`Canvas v10 will be released on `}<strong parentName="p">{`October 25th, 2023`}</strong>{` with updates to Canvas Kit. An upgrade guide
will be available once v10 goes live to assist product teams with upgrading to the newest version of
Canvas.`}</p>
      <p>{`The v10 release will focus on revamping Canvas tokens and components to support a Canvas theme,
starting with Buttons! This Canvas theme will serve as the foundation for future theming work, which
could include brand specific and user selected themes, like dark mode.`}</p>
      <h3 {...{
        "id": "release-overview"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v10 release. The impact for developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v9`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Token Structure & Naming Convention`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new token structure and updated token naming convention will be rolled out`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Opacity System Token`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new opacity token will be available for use with disabled states`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Styling Strategy for Buttons`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new styling strategy will be rolled out to Canvas buttons in order to make buttons compatible with CSS variables while moving away from Emotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Token Rollout to Canvas Buttons`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new token set`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Space & Depth Rem Conversion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Space and Depth tokens will be converted from pixel to rem`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Deprecation of spaceNumbers`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`In favor of moving to rems, `}<inlineCode parentName="td">{`spaceNumbers`}</inlineCode>{` will be deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Select Compound Component Conversion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Select compound component will replace the current Select component in Main`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Deprecation of Select in Preview`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Select component in Preview will be deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Deprecation of `}<inlineCode parentName="td">{`InputIconContainer`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The `}<inlineCode parentName="td">{`InputIconContainer`}</inlineCode>{` utility will be deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Deprecation of Table in Main`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Table component in Main will be deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "canvas-v10-release"
      }}>{`Canvas v10 Release`}</h2>
      <p>{`Canvas v10 will be released on `}<strong parentName="p">{`October 25th, 2023`}</strong>{` with updates to Canvas Kit and the Canvas
Figma libraries. An upgrade guide will be available once v10 goes live to assist product teams with
upgrading to the newest version of Canvas.`}</p>
      <p>{`The v10 release will focus on revamping Canvas tokens and components to support a Canvas theme,
starting with Buttons! This Canvas theme will serve as the foundation for future theming work, which
could include brand specific and user selected themes, like dark mode.`}</p>
      <h3 {...{
        "id": "release-overview-1"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v10 release. The impact for designers and developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v9 and/or switch to the new
v10 Canvas Web Figma library`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Designer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Token Structure & Naming Convention`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new token structure and updated token naming convention will be rolled out`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Space and Shape Figma Variables`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Space and Shape tokens will be available for designers to utilize as pre-set Figma variables`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Opacity System Token`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new opacity token will be available for use with disabled states`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Styling Strategy for Buttons`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new styling strategy will be rolled out to Canvas buttons in order to make buttons compatible with CSS variables while moving away from Emotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Token Rollout to Canvas Buttons`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new token set`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Tertiary Inverse Button Styling Standardization`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The background color for the hover state of the inverse Tertiary button will be updated to align with the inverse Primary and Secondary button`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Button Focus Ring Update`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Focus rings across Primary and Secondary buttons will be more consistent`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Removal of 13px Figma Font Styles`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Previously deprecated 13px Figma font styles will be removed`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Tooltip Font Style Update`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Tooltip will be updated from using a deprecated 13px font style to a supported font style`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Space & Depth Rem Conversion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Space and Depth tokens will be converted from pixel to rem`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Deprecation of spaceNumbers`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`In favor of moving to rems, `}<inlineCode parentName="td">{`spaceNumbers`}</inlineCode>{` will be deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Select Compound Component Conversion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Select compound component will replace the current Select component in Main`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Deprecation of Select in Preview`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Select component in Preview will be deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Deprecation of `}<inlineCode parentName="td">{`InputIconContainer`}</inlineCode></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The `}<inlineCode parentName="td">{`InputIconContainer`}</inlineCode>{` utility will be deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Deprecation of Table in Main`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Table component in Main will be deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
    </InternalContent>
    <h3 {...{
      "id": "new-token-structure-and-naming-convention"
    }}>{`New Token Structure and Naming Convention`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7490bc01a598ee6241caa4d61d423920/60b3a/token-structure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.67567567567568%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6UlEQVR42o2SS08TURSA+Se6JfUVoZSXndIXfWpjqLBp1YUa/4DGRHds/COsNOKj8hARSYiIoCYsjIkb0QWtfc2r02ln5nPmlkrcMcnNmbkz55vvnHMHOMHlOI6IzabMs5dL/63npRWeLJbY3NoW3wz0E+qNOuVKhXq9jmma4mW1WuOwXHZjVTx/+LiLzx9CSlxlLJLlwniUwaEgp30jZPJFjHa7B7RsqDRUdE3Ftm2R3LG6VGp/0DSdTrcr9rY/7TEZzxHNzhKYSjMSSrkxxdnRCNeKdzAMD+jaHVYb/KwoKIpMU5ZFaZrugTr0fmgfAT8jxS8TimeYmEowEU4gRVNcHA8zU7jVA3rl1mp12mYH2+mV7y3Lsugemdn/gHuEkzkiiSzBWAoplhbw82MR8sXbx4ZNWUVrtd1Mk5ZhCEDXLVk3WjhuP1RNE8Cd3S9uDyXOBUIMTcbwB6cJhJKcCYTJ9w1dJ2RV5+uByv4vRdw7LtC0OuwffOP77x/IitIz3HEN03nmbt7l0nSO4WCS0bBnGGX2utdDoz8UhwevmhQW3EmrlkjWTYOHi4+593QeudUDrr/f4v6jed5ubHJl7ganBv34hiURMzOF4yk7JzyHnmlpeY0Xr1dZWl1n+c07VtY2xJ5n753DvyHJR3wIN+/kAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image displaying the relationship between base, system, and brand level tokens. Base tokens are tied to hard coded values that can then be referenced by system or brand level token. System and brand level tokens directly tie into Canvas components.",
            "title": "Image displaying the relationship between base, system, and brand level tokens. Base tokens are tied to hard coded values that can then be referenced by system or brand level token. System and brand level tokens directly tie into Canvas components.",
            "src": "/static/7490bc01a598ee6241caa4d61d423920/50383/token-structure.png",
            "srcSet": ["/static/7490bc01a598ee6241caa4d61d423920/1efb2/token-structure.png 370w", "/static/7490bc01a598ee6241caa4d61d423920/50383/token-structure.png 740w", "/static/7490bc01a598ee6241caa4d61d423920/60b3a/token-structure.png 1179w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "/styles/tokens/overview"
      }}>{`Canvas tokens`}</a>{` will be restructured in Canvas v10 to:`}</p>
    <ul>
      <li parentName="ul">{`Enable scalable visual changes at the core token level that flows across all Canvas components`}</li>
      <li parentName="ul">{`Increase cohesion across Canvas components by standardizing token usage with semantic tokens`}</li>
      <li parentName="ul">{`Empower product teams to build custom components using semantic tokens that can evolve with Canvas
tokens`}</li>
    </ul>
    <p>{`There are three level of Canvas tokens, which includes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Base Tokens:`}</strong>{` tokens that represent hard coded values like a hex code or rem value. These
tokens are maintained by the Canvas Design System team and should rarely be used by product teams.`}</li>
      <li parentName="ul"><strong parentName="li">{`System Tokens:`}</strong>{` tokens that are intended to be used by product teams. They are semantic,
meaning that they are named according to how they should be used. These tokens are the most
similar to what is available in Canvas today (ex: type, depth).`}</li>
      <li parentName="ul"><strong parentName="li">{`Brand Tokens:`}</strong>{` tokens that are used to set key brand colors and can be used by tenants to theme
certain components to reflect their brand color.`}</li>
    </ul>
    <p>{`With the current token structure, changing the hex value of Cinnamon 500 will result in a change to
all components using Cinnamon 500, regardless of the context in which it is being used. This new
three layer token structure along with the shift to a more semantic naming format allows the Canvas
Design System to maintain and modify base tokens without impacting the intended use cases of system
or brand tokens. As a result, teams using system tokens and brand tokens based on their intended use
cases will be able to uptake those changes without the need for modifying their components.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: Color system tokens are still in development and will not be ready for the Canvas v10
release, but all other system tokens (ex: depth, space) will be available at the start of v10.`}</p>
    </blockquote>
    <p>{`This new token set will also use rem as a unit of measurement instead of pixels to standardize
Canvas tokens and improve the scalability of tokens across various screen sizes. All tokens will use
rem as a unit of measurement, except for color tokens which will continue to utilize hex codes.`}</p>
    <p><img parentName="p" {...{
        "src": "../../../resources/token-naming.png",
        "alt": "Token name are broken down into four parts: namespace, category, type, and modifire. The namespace is prefixed with \"$cnvs\" to tie it back to Canvas. The category represents the token tier, such as base or system. Type is used to categorize the token such as color, brand, or shape. Modifier is used to add specificity to the token such as \"primary.main\"."
      }}></img></p>
    <p>{`To support the new token structure, a new naming convention will also be implemented to help teams
easily distinguish between different token levels.`}</p>
    <p>{`This new token structure and naming convention will not result in breaking changes in code since the
current set of tokens are not being modified or deprecated as part of v10. Instead, a new Canvas
Tokens repo will be created to store the new set of tokens, which will also be ported into Canvas
Kit React. The current token set will not be deprecated until Canvas fully migrates over to the new
token structure and naming convention. However, developers will be able to use the new token set as
part of v10.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`For designers, the current Canvas Tokens Figma library will be renamed to “Canvas Tokens v1” and a
new Canvas Tokens v2 Figma library will be created to store the new set of tokens and give designers
more control with their token upgrades. Designers staying on Canvas v9 or older will still have
access to the same token set and subsequent Figma styles. In order to use the new token set,
designers upgrading to Canvas v10 will need to:`}</p>
      <ul>
        <li parentName="ul">{`Perform a library swap to the new Canvas v10 Figma library`}</li>
        <li parentName="ul">{`Turn on the new Canvas Tokens v2 Figma library`}</li>
        <li parentName="ul">{`And turn the old Canvas Tokens v1 library off`}</li>
      </ul>
    </InternalContent>
    <p>{`Please note that this work is ongoing and will span across multiple Canvas releases. However, the
new token set is subject to change as they are rolled out across all Canvas components.`}</p>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "new-space-and-shape-figma-variables"
      }}>{`New Space and Shape Figma Variables`}</h3>
      <p>{`New
`}<a parentName="p" {...{
          "href": "https://help.figma.com/hc/en-us/articles/15339657135383-Guide-to-variables-in-Figma"
        }}>{`Figma variables`}</a>{`
will be released with v10 for Canvas space and shape tokens. These Figma variables will contain the
pre-set values of all Canvas space and shape tokens to help designers quickly switch between
different space and shape tokens without needing to reference documentation or memorize the token
values. Variables are only visible when using the associated design property. As such, space
variables are only visible within the Auto Layout window while shape variables can only be utilized
as corner radiuses in Figma.`}</p>
      <p>{`To learn more about variables, view
`}<a parentName="p" {...{
          "href": "https://www.youtube.com/watch?v=1ONxxlJnvdM"
        }}>{`Figma’s tutorial on intro to variables`}</a>{`.`}</p>
      <p>{`This update is Figma specific and will not impact developers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "new-opacity-system-token"
    }}>{`New Opacity System Token`}</h3>
    <p>{`A new opacity token will now be introduced with the new token structure. The opacity disabled token
will be the only opacity system token introduced in v10. It is set to 40% opacity to be used with
disabled states, which does not have to meet contrast requirements to be accessible.`}</p>
    <p>{`This token will be available in code for developers with the new token set.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`However, opacity tokens will not be available as a Figma as opacity is currently not supported in
Figma as a style or variant.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "new-styling-strategy-for-buttons"
    }}>{`New Styling Strategy for Buttons`}</h3>
    <p>{`A new styling strategy will start rolling out in Canvas v10 in parallel with the token and theming
work, starting with Canvas buttons. Canvas will begin shifting towards static styling with the use
of CSS variables as opposed to dynamic styling via Emotion to improve performance and reduce the
need for full page style recalculations.`}</p>
    <p>{`As part of this work, Canvas buttons styles will be refactored to use the new `}<inlineCode parentName="p">{`cs`}</inlineCode>{` styling function.
The style refactoring will allow Primary, Secondary, Tertiary, and Delete buttons to use the new
tokens in the form of CSS variables through the CanvasProvider.`}</p>
    <p>{`While Canvas will be moving away from styling with Emotion, Canvas users will still be able to use
Emotion. This update will not impact developers. More information about the new styling strategy can
be found in this `}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/discussions/2265"
      }}>{`GitHub discussion`}</a>{`.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "token-rollout-to-canvas-buttons"
    }}>{`Token Rollout to Canvas Buttons`}</h3>
    <p>{`Canvas’s Primary, Secondary, Tertiary, and Delete buttons will be updated to use the new system and
brand level tokens. Elements that are meant to be brandable, like the text color of Primary buttons,
will be tied to a brand token. However, certain elements will be tied to base level tokens for the
time being as new system tokens are created throughout the roll out process.`}</p>
    <p>{`This update will not be a breaking change for developers, but developers will be able to see the new
system and brand tokens being used to create Canvas buttons.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This will also not be a breaking change for designers as the Canvas buttons in the Canvas Web v10
Figma library will be linked to the new system and brand tokens automatically. Since Canvas
components have always been structured to reference tokens, the new token set will also be rolled
out across all Canvas components in the Canvas Web v10 Figma library. Please note that this will
temporarily create a discrepancy between what is available in code as Canvas components must be
refactored in code before they can uptake the new token set.`}</p>
      <h3 {...{
        "id": "tertiary-inverse-button-styling-standardization"
      }}>{`Tertiary Inverse Button Styling Standardization`}</h3>
      <p>{`The inverse variant of Tertiary buttons will be updated to align with the styling of the inverse
variants of the Primary and Secondary buttons using the new token set.`}</p>
      <p>{`By connecting the inverse variant of Tertiary buttons to the same set of tokens used by Primary and
Secondary buttons, the background color for the hover state of the inverse Tertiary buttons will be
updated from French Vanilla 100 to Soap 300.`}</p>
      <p>{`The new background color will be applied automatically once designers upgrade to Canvas v10 and will
not result in any breaking changes. This update will also be reflected in code, but no action will
be required from developers to uptake this change.`}</p>
      <h3 {...{
        "id": "button-focus-ring-update"
      }}>{`Button Focus Ring Update`}</h3>
      <p>{`We found that focus rings were not consistent across all buttons. We've updated the focus ring on
the `}<inlineCode parentName="p">{`inverse`}</inlineCode>{` variant of `}<inlineCode parentName="p">{`PrimaryButton`}</inlineCode>{` to display a consistent focus ring across `}<inlineCode parentName="p">{`PrimaryButton`}</inlineCode>{`
and `}<inlineCode parentName="p">{`SecondaryButton`}</inlineCode>{`. The changes to `}<inlineCode parentName="p">{`PrimaryButton`}</inlineCode>{` will need to be taken note of due to small
visual changes with the `}<inlineCode parentName="p">{`inverse`}</inlineCode>{` variant.`}</p>
      <p>{`Also, `}<inlineCode parentName="p">{`colors`}</inlineCode>{` will no longer support the `}<inlineCode parentName="p">{`focusRing`}</inlineCode>{` option.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-tsx"
        }}>{`import {focusRing} from '@workday/canvas-kit-react/common';

// v9
<PrimaryButton
  colors={{
    // other colors
    focus: {
      // other colors
     focusRing: focusRing(/* options */)
    }
  }}
/>

// v10
<PrimaryButton
  colors={{
    // other colors
    focus: {
      // other colors
    }
  }}
  css={{
    ':focus-visible': focusRing(/* options */)
  }}
/>;
`}</code></pre>
      <h3 {...{
        "id": "removal-of-13px-figma-font-styles"
      }}>{`Removal of 13px Figma Font Styles`}</h3>
      <p>{`Previously deprecated 13px type styles will be removed in the Canvas Tokens v2 Figma library. These
font sizes were deprecated in Canvas v5 and while they’ll still be available for designers through
the Canvas Tokens V1 library, they will not be supported in the Canvas Tokens v2 Figma library.`}</p>
      <p>{`When designers swap over to the Canvas Tokens v2 Figma library, type styles using 13px font size
will no longer be available for use in the Figma styles menu. However, all other type styles will
still be supported in the new Canvas Tokens v2 Figma library. Designs using the deprecated 13px text
styles will persist after the library swap, but it is recommended for designers to switch over to
using a supported text style to align with other Workday products.`}</p>
      <p>{`This update is Figma specific and will not impact developers.`}</p>
      <h3 {...{
        "id": "tooltip-font-style-update"
      }}>{`Tooltip Font Style Update`}</h3>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d251af580fd34dfb97885726bcbf1996/891d5/v10-tooltip.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "53.783783783783775%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJklEQVR42rVSy06DQBTlo+qOhMRIqRo3fIBlBbWyUDb8GFu2bPkD3RfkOTOU8jgOQ6hNrcYavclN7sycnHvmnivhj0M6B9z3vcip/hHhV8BfKWyaBlEUIUmSvZrDBnEci8yyTOAYY6cJu64ThyAIYFkW1utHPD07sG0bjuOgLEuBcV0XhmFgtXqAaZpYLu8RhiEOOUbCthUHz/OgKApurzXod3Oocw2LhYY0TdFyjK7rkGUZN6oC9eoSs9kFfN//TDhFyy9LykCyDcjmBSVhoNXHtypeE/5O41fQ/A2UVXui06bwGXZVxdU0aHdbrrxDz+jg1NiQqxyy3lLUdSXq712uaxA+7IJQ5HkBQgh23KCJcDChKMb7PM9FfbwV0v8v9tGqnEv4Dq7uPNIVBt8PAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image displaying the before (Tooltip in v9) and after (Tooltip in v10) view",
              "title": "Image displaying the before (Tooltip in v9) and after (Tooltip in v10) view",
              "src": "/static/d251af580fd34dfb97885726bcbf1996/50383/v10-tooltip.png",
              "srcSet": ["/static/d251af580fd34dfb97885726bcbf1996/1efb2/v10-tooltip.png 370w", "/static/d251af580fd34dfb97885726bcbf1996/50383/v10-tooltip.png 740w", "/static/d251af580fd34dfb97885726bcbf1996/891d5/v10-tooltip.png 1520w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`Tooltip will be updated in Figma to use the Subtext M - (400) Regular text style instead of the
deprecated 13px text styles to align with what is available in code. The current 13px text styles
have been deprecated since v5 and will be fully removed in v10. This update will not increase the
line height of tooltip, but it will decrease the width of tooltip as the font size will decrease to
0.75 rem (the equivalent of 12px). The top and bottom padding will also increase from 0.375 to .5
rems (the equivalent of moving from 6 to 8px) in order to maintain the current height of the tooltip
component.`}</p>
      <p>{`This update is Figma specific and will not impact developers. However, it will result in a visual
change for designers, but the update will be applied automatically once designers upgrade to v10.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "space-and-depth-rem-conversion"
    }}>{`Space and Depth Rem Conversion`}</h3>
    <p>{`The current space and depth tokens in Canvas Kit will be updated to use rem instead of pixels in
order to maintain consistency across all Canvas tokens and align with the new unit of measurement
that will be utilized by the new token set. `}<inlineCode parentName="p">{`spaceNumbers`}</inlineCode>{` will continue to be based in pixels, but
will be `}<a parentName="p" {...{
        "href": "/help/glossary/#deprecation"
      }}>{`deprecated`}</a>{` in v10 as part of the transition
over to rem based tokens.`}</p>
    <p>{`This update to rem will impact developers using string literals. Additional resources will be
provided to help developers switch over to rem.`}</p>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/discussions/2343"
      }}>{`discussion`}</a>{` on `}<inlineCode parentName="p">{`px`}</inlineCode>{` to `}<inlineCode parentName="p">{`rem`}</inlineCode>{`
migration.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "deprecation-of-spacenumbers"
    }}>{`Deprecation of spaceNumbers`}</h3>
    <p><inlineCode parentName="p">{`spaceNumbers`}</inlineCode>{` will be `}<a parentName="p" {...{
        "href": "/help/glossary/#deprecation"
      }}>{`deprecated`}</a>{` in v10 as part of
the move towards rem based tokens. Teams using `}<inlineCode parentName="p">{`spaceNumbers`}</inlineCode>{` can continue to do so in v10, but
deprecation warnings will appear when it is in use.`}</p>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/discussions/2343"
      }}>{`discussion`}</a>{` on `}<inlineCode parentName="p">{`px`}</inlineCode>{` to `}<inlineCode parentName="p">{`rem`}</inlineCode>{`
migration.`}</p>
    <p>{`It is recommended for developers to switch over to using
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/calc"
      }}>{`CSS calc()`}</a>{` instead as `}<inlineCode parentName="p">{`spaceNumbers`}</inlineCode>{` will
eventually be removed.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "select-compound-component-conversion"
    }}>{`Select Compound Component Conversion`}</h3>
    <p>{`The Select component in Main will be replaced with a Select compound component as part of an effort
to standardize component APIs and give developers more flexibility with customizing Canvas
components.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/40ca1b89eff3a0e38d344239d2ead573/d0ab7/v10-select.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.37837837837838%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABs0lEQVR42q1SS24UMRCd63ACFCSEUDgIKy6CxAYhiFAYZRtxCFYRYkFyBaKZoeejxv1zd9vu6fan+1F2SM8kmR0p6bls2fXqlasm+B8bhh3+2cQvfd/DuR7WOfLuYKx/c/vOw+8PWSBUVYoinqNKlxD5mjKqG/SSfBOSzGe/Rizm11hGc0hRQtQFGiX3CQdkWYrfTIDVBnGlR2xKDZZztFuFPJ6h4WvILIKRDNAc6AqgzSCKDYzrdwqnFyu8+Bzj1TTBy1MWcPyF4eiE4c3XCGrb4fX5Bs8/Rnj6fjHi2YcFnryd4d23JJB5zqCwLqnkWmGrHTo7jGgtCdkKtJ3G+RXHyXeOs8sG058q4OxS4dMPhYvrxnco9Gbi104W0MbAEPzFnUZqCaM1/SPdDf7+YTOsddSkYVeyKGKUZYWu64j/PqGgRBpN25FSS4ltKG0f2rgxLhBKzsCSFIwlKDhHlufg5H1WT2iJUAiJtm0PjtUDhYr/Qc6WKNgKRbJCFkfgyRq6KamjKQVYSKmoihJVXYfzPrQ2dxX6fxyGPgDB09kPsrOU+UaRV3Y73PcJ91VP8Mj2F/2IlQmHN1DuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Image displaying the before view (Select in Preview for v9) and the after view (updated v10 Select component in Main)",
            "title": "Image displaying the before view (Select in Preview for v9) and the after view (updated v10 Select component in Main)",
            "src": "/static/40ca1b89eff3a0e38d344239d2ead573/50383/v10-select.png",
            "srcSet": ["/static/40ca1b89eff3a0e38d344239d2ead573/1efb2/v10-select.png 370w", "/static/40ca1b89eff3a0e38d344239d2ead573/50383/v10-select.png 740w", "/static/40ca1b89eff3a0e38d344239d2ead573/d0ab7/v10-select.png 1532w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This new Select component will be visually similar to the Select in Preview, but with the following
design changes:`}</p>
    <ul>
      <li parentName="ul">{`The focus ring, error ring, and warning ring will no longer wrap around the menu for Select.
Instead, rings will only wrap around the Select input to better align borders across different web
browsers.`}</li>
      <li parentName="ul">{`The spacing between the menu and its target element will increase from `}<inlineCode parentName="li">{`0px`}</inlineCode>{` to `}<inlineCode parentName="li">{`4px`}</inlineCode>{` in order to
prevent the menu from overlapping with the focus, error, and warning rings. This change is being
made to the Popup component directly, which will flow into Menu and Select as well.`}</li>
      <li parentName="ul">{`The maximum height of the menu has been increased from `}<inlineCode parentName="li">{`200px`}</inlineCode>{` to `}<inlineCode parentName="li">{`300px`}</inlineCode>{` to accommodate 7
single-line items (the minimum number of items we recommend including in a Select) without
triggering overflow. The maximum height may be overriden.`}</li>
    </ul>
    <p>{`Developers using the current Select component in Main will have to change the API to match the new
compound component's API. It is also recommended for developers using the Select component in
Preview to switch over to the compound component version of Select, which will give them more
flexibility and control over Select.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`The compound component conversion is a code specific change, but the visual changes will be applied
to the Select component in the Canvas Web v11 Figma library. The Select component in Figma is
reflective of the Select component in Preview, which is still available for use in v10. No changes
will be made to the Select component in Figma for the time being to minimize the number of breaking
changes for designers in this release.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "deprecation-of-select-in-preview"
    }}>{`Deprecation of Select in Preview`}</h3>
    <p>{`The Select component in Preview will be `}<a parentName="p" {...{
        "href": "/help/glossary/#deprecation"
      }}>{`deprecated`}</a>{`
in Canvas Kit with the introduction of the new Select compound component in Main. Select in Preview
will still be available for use in Canvas v10, but deprecation warnings will appear when it is in
use.`}</p>
    <p>{`Developers should start replacing all instances of Select in Preview with the new Select compound
component in Main, which will be available as part of the v10 release.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "deprecation-of-inputiconcontainer"
    }}>{`Deprecation of InputIconContainer`}</h3>
    <p><inlineCode parentName="p">{`InputIconContainer`}</inlineCode>{` will be `}<a parentName="p" {...{
        "href": "/help/glossary/#deprecation"
      }}>{`deprecated`}</a>{` in Canvas
Kit. It will still be available for use in Canvas v10, but deprecation warnings will appear when it
is in use.`}</p>
    <p>{`Developers using `}<inlineCode parentName="p">{`InputIconContainer`}</inlineCode>{` should switch over to using `}<inlineCode parentName="p">{`InputGroup`}</inlineCode>{`, which has an added
bidirectionality feature and can support icons at the start of an input.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "deprecation-of-table-in-main"
    }}>{`Deprecation of Table in Main`}</h3>
    <p>{`The Table component in Main will be `}<a parentName="p" {...{
        "href": "/help/glossary/#deprecation"
      }}>{`deprecated`}</a>{` in
Canvas Kit. It will still be available for use in Canvas v10, but deprecation warnings will appear
when it is in use.`}</p>
    <p>{`Developers using the Table component in the Main package should switch over to using the new Table
in Preview, which has been updated to be more flexible with a more modern design.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This is a code specific update and will not impact designers.`}</p>
    </InternalContent>
    <hr></hr>
    <h2 {...{
      "id": "previous-releases"
    }}>{`Previous Releases`}</h2>
    <InternalContent mdxType="InternalContent">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v9-announcements"
          }}>{`Canvas v9 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v8-announcements"
          }}>{`Canvas v8 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v7-announcements"
          }}>{`Canvas v7 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v6-announcements"
          }}>{`Canvas v6 Announcements`}</a></li>
      </ul>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v9-announcements"
          }}>{`Canvas v9 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v8-announcements"
          }}>{`Canvas v8 Announcements`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/announcements/canvas-v7-announcements"
          }}>{`Canvas v7 Announcements`}</a></li>
      </ul>
    </ExternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      